<template>
  <div class="home" >
    
  <section class="home-one home1-overlay bg-home4" id="search">
   
    <div class="container">
      
    <div class="row justify-content-center">
        <div class="" style="background-color:transparent !important;z-index:1!important;opacity:;">
        <div class="mobile-menu" style="background-color:transparent !important">
          <div class="header" style="background-color:transparent !important">
            <div class="main_logo_home2 text-center" style="margin-top:5px !important"><img class="nav_logo_img img-fluid mt10" style="z-index:999 !important;opacity:1 !important" src="../assets/static/avanza-white.png" alt="header-logo.svg"></div>
            <ul class="menu_bar_home2" >
              <li class="list-inline-item"><a class="custom_search_with_menu_trigger msearch_icon" href="#"></a></li>
              <li class="list-inline-item"><!--<a class="muser_icon" href="#" data-toggle="modal" data-target="#logInModal"><span class="flaticon-user"></span></a>--></li>
              <li class="list-inline-item"><a style="margin-top:10px !important" class="menubar" href="#menu"><span></span></a></li>
            </ul>
          </div>
        </div>
        </div>
        <div class="col-lg-10 ">
          <div class="home_content row justify-content-center home4">
            <div class="home-text text-center d-none d-md-inline-block">
              <h3 class="fz40">Encuentra tu nueva casa, ¡te ayudamos en todo!</h3>
              <p class="fz18 color-white">¡Apuesta por la gestión inmobiliaria eficaz!</p>
            </div>
            <div class="home-text text-center d-inline-block d-md-none row justify-content-center mx-1" style="position:absolute;top:50%">
              <h3 class="fz40">Las cosas bien hechas nunca fueron tan fáciles.</h3>
              <p class="fz18 color-white">¡Da la bienvenida a la gestión inmobiliaria profesional!</p>
              <router-link to="/listado-inmuebles?operacion=venta" class="btn btn-block search-tab" style="font-size:16px;border-radius:15px"><span class="fa fa-search mr-3"></span>¡Buscar inmuebles! 🏡</router-link>
            </div>
            <div class="home4">
                  <div class="home_adv_srch_opt home4">
                    <div class="wrapper">
                      <div class="home_adv_srch_form home4 d-none d-md-inline-block">
                          <div class="container">
                          <div class="row justify-content-center mb-3">
                            <div class="col-6 col-lg-3">
                              <button class="btn btn-block py-2 " :class="(operacion=='venta') ? 'search-tab-selected' : 'search-tab'" @click="()=>{this.operacion='venta'}">
                                Venta
                              </button>
                            </div>
                            <div class="col-6 col-lg-3">
                              <button class="btn btn-block py-2 " :class="(operacion=='alquiler') ? 'search-tab-selected' : 'search-tab'" @click="()=>{this.operacion='alquiler'}">
                                Alquiler
                              </button>
                            </div>
                          </div>
                          <div class="row justify-content-center bgc-white p-3" style="border-radius:100px">
                            <div class="col-12 col-lg-3 ">
                              <div class="form-group style2 mb-0"> 
                                <div class="row">
                                  <div class="col">
                                    <label>Tipo de inmueble</label>
                                    <div class="ui_kit_select_box">
                                      <multiselect class="search_bar" v-model="tipos" :options="prop_tipos" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Tipos de inumeble" label="tipo" track-by="tipo" :preselect-first="false" :show-labels="false">
                                        <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ tipos.length }} seleccionados</span></template>
                                      </multiselect>
                                    </div>
                                  </div>
                                  <div class="separator d-none d-lg-inline-block"></div>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 col-lg-3 home_form_input">
                              <div class="form-group style2 mb-0">
                                <div class="row">
                                  <div class="col">
                                    <label>Ciudad</label>
                                    <multiselect class="search_bar" v-model="ciudad" deselect-label="" :close-on-select="true" track-by="city" label="city" placeholder="Todas las ciudades" :options="prop_ciudades" :searchable="true" :allow-empty="true" :show-labels="false">
                                      <template slot="singleLabel" slot-scope="{ option }">{{ ciudad.city }}</template>
                                    </multiselect>
                                  </div>
                                  <div class="separator d-none d-lg-inline-block"></div>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 col-lg-2 home_form_input">
                              <div class="form-group style1 mb-0">
                                <div class="row">
                                  <div class="col">
                                    <label>Desde</label>
                                    <div class="select-wrap style1-dropdown">
                                      <input v-model="precio_desde" type="number" class="form-control search_bar" id="" placeholder="€" min="0">
                                    </div>
                                  </div>
                                  <div class="separator d-none d-lg-inline-block"></div>
                                </div>
                              </div>
                            </div>
                             <div class="col-12 col-lg-2 home_form_input">
                              <div class="form-group style1 mb-0">
                                <label>Hasta</label>
                                <div class="select-wrap style1-dropdown">
                                  <input v-model="precio_hasta" type="number" class="form-control search_bar" id="" placeholder="€" min="0">
                                </div>
                              </div>
                            </div>
                            <div class="col-12 col-lg-2 home_form_input2 mt-lg-2">
                              <button @click="searchProperties()" type="submit" class="btn search-btn"><span class="fa fa-search"></span> Buscar</button>
                            </div>
                          </div>
                          </div>
                      </div>
                    </div>
                  </div> 
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Recent Property For Rent -->
  <section class="recent-property">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="main-title">
            <h2>Inmuebles destacados en Avanza</h2>
            <p>Estos son los últimos inmuebles añadidos:</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 px-2">
          <Carousel :perPageCustom="[[0,1],[480, 2], [768, 3]]" :autoplay="true" :loop="true">
            <Slide v-for="(inmu,index) in inmuebles" v-bind:key="index" class="px-xl-3" >
              <Property 
              :idProp="inmu.cod_ofer"
              :image="inmu.foto"
              :tipo="inmu.nbtipo"
              :dorm="inmu.habitaciones+inmu.habdobles"
              :banyos="inmu.banyos"
              :sup="inmu.m_cons"
              :referencia="inmu.ref"
              :precioInmo="inmu.precioinmo"
              :precioAlq="inmu.precioalq"
              :precioCom="inmu.outlet"
              :ciudad="inmu.ciudad"
              :operacion="inmu.keyacci"
              :zona="inmu.zona"
              :numFotos="inmu.numfotos"
              :colLg="12"
              :colXl="12"
              />
            </Slide>
          </Carousel>    
        </div>
      </div>
    </div>
  </section>

  <section class="">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="main-title">
            <h2>¿En qué te podemos ayudar?</h2>
            <p>En Avanza Inmuebles, queremos darte el mejor servicio, para ti, ¡y para tu hogar!</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
          <router-link to="/listado-inmuebles?operacion=venta">
          <div class="property_city_home6 tac-767 shadow" style="border-radius:20px">
            <div class="thumb"><img class="fluid-img p-3" src="../assets/static/house-icon.png" alt="house-icon"></div>
            <div class="details mt-2" >
              <h4 style="font-weight:800;">Comprar mi vivienda
              <br> <span style="color:#E45726">ideal</span>
              </h4>
              <div class="flecha-boton"></div>
            </div>
          </div>
          </router-link>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
          <router-link to="/listado-inmuebles?operacion=alquiler" >
          <div class="property_city_home6 tac-767 shadow" style="border-radius:20px">
            <div class="thumb"><img class="fluid-img p-3" src="../assets/static/house-icon.png" alt="house-icon"></div>
            <div class="details mt-2" >
              <h4 style="font-weight:800;">Alquilar inmueble
              <br> <span style="color:#E45726">¡y empezar!</span>
              </h4>
              <div class="flecha-boton"></div>
            </div>
          </div>
          </router-link>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
          <router-link to="/vender-alquilar">
          <div class="property_city_home6 tac-767 shadow" style="border-radius:20px">
            <div class="thumb"><img class="fluid-img p-3" src="../assets/static/house-icon.png" alt="house-icon"></div>
            <div class="details mt-2" >
              <h4 style="font-weight:800;">Vender mi inmueble
              <br> <span style="color:#E45726">rápido y fácil</span>
              </h4>
              <div class="flecha-boton"></div>
            </div>
          </div>
          </router-link>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
          <a href="https://proycagrupo.com/">
            <div class="property_city_home6 tac-767 shadow" style="border-radius:20px">
              <div class="thumb"><img class="fluid-img p-3" src="../assets/static/house-icon.png" alt="house-icon"></div>
              <div class="details mt-2" >
                <h4 style="font-weight:800;">Reformar mi casa
                <br> <span style="color:#E45726">a medida</span>
                </h4>
                <div class="flecha-boton"></div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </section>
  <!--
  <section class="pb70">
    <div class="container rounded banner-home">
      <div class="row justify-content-center" style="padding:100px 0;">
        <div class="col text-center dark-text">
          <h3 >La inmobiliaria donde hacemos que las cosas pasen.</h3>
          <p class="dark-text">Elige a profesionales, de verdad, con más de 25 años de experiencia.</p>
          <br>
          <router-link to="/encontrar-un-hogar" class="btn btn-light">Te ayudamos a encontrar tu casa ideal.</router-link>
        </div>
      </div>
    </div>
  </section>
  -->

  <section class="recent-property">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="main-title">
            <h2>Últimos inmuebles en Avanza</h2>
            <p>Estos son nuestros últimos inmuebles:</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 px-2">
          <div class="row">
            <Property v-for="(inmu,index) in novedades" v-bind:key="index"
              :idProp="inmu.cod_ofer"
              :image="inmu.foto"
              :tipo="inmu.nbtipo"
              :dorm="inmu.habitaciones+inmu.habdobles"
              :banyos="inmu.banyos"
              :sup="inmu.m_cons"
              :referencia="inmu.ref"
              :precioInmo="inmu.precioinmo"
              :precioAlq="inmu.precioalq"
              :precioCom="inmu.outlet"
              :ciudad="inmu.ciudad"
              :operacion="inmu.keyacci"
              :zona="inmu.zona"
              :numFotos="inmu.numfotos"
              :colLg="6"
              :colXl="4"
            />
          </div>   
        </div>
      </div>
      <div class="row mt-5 justify-content-center mb-5">
        <div class="col-12 col-md-6 col-lg-4">
        
        <router-link to="/listado-inmuebles?operacion=venta" class="btn btn-block py-3 px-5 orange-button">Ver todos</router-link>

        </div>
      </div>
    </div>
  </section>

  <section class="pb70" id="opiniones">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-6">
          <h2 class="text-white text-center mb-5" style="font-weight:400">
            <span style="color:#E45726;font-weight:800">+ 5000 clientes</span> han confiado en Avanza para vender su inmueble
          </h2>
          <Carousel :perPageCustom="[[0,1]]" :autoplay="true" :loop="true">
          <Slide class="text-center px-4">
            <span class="text-white" style="font-weight:700;font-size:16px">
              Ana Maria Valero
              <span class="fa fa-star mr-1 ml-2 text-warning"></span>
              <span class="fa fa-star mr-1 text-warning"></span>
              <span class="fa fa-star mr-1 text-warning"></span>
              <span class="fa fa-star mr-1 text-warning"></span>
              <span class="fa fa-star text-warning"></span>
            </span>
            <p class="text-white">
              Mi experiencia con Cristina fue fabolusa, gran profesional, trato impecable, atenta y comprometida con la necesidad del cliente. Me resultó muy cómodo gestionar todo con ella pues tiene una gran formación en el sector y ganó mi confianza desde el inicio. Volvería a contar con esta agencia inmobiliaria y con los servicios de esta trabajadora. Grandes asesores del sector inmobiliario
            </p>
          </Slide>  
          <Slide class="text-center px-4">
            <span class="text-white" style="font-weight:700;font-size:16px">
              Irene Romero
              <span class="fa fa-star mr-1 ml-2 text-warning"></span>
              <span class="fa fa-star mr-1 text-warning"></span>
              <span class="fa fa-star mr-1 text-warning"></span>
              <span class="fa fa-star mr-1 text-warning"></span>
              <span class="fa fa-star text-warning"></span>
            </span>
            <p class="text-white">
              Trato inmejorable, cercano y cordial, nos atendió José Luis y quedamos encantados con él, muy amable y atento,rapidez y profesionalidad. Un acierto trabajar con ellos.
            </p>
          </Slide>
          <Slide class="text-center px-4">
            <span class="text-white" style="font-weight:700;font-size:16px">
              Raquel Aparicio
              <span class="fa fa-star mr-1 ml-2 text-warning"></span>
              <span class="fa fa-star mr-1 text-warning"></span>
              <span class="fa fa-star mr-1 text-warning"></span>
              <span class="fa fa-star mr-1 text-warning"></span>
              <span class="fa fa-star text-warning"></span>
            </span>
            <p class="text-white">
              José Luis ha tenido un trato excelente, totalmente a disposición y facilitando todos los trámites. Sin duda, si tenemos que realizar alguna otra compra-venta, acudiremos aquí.            
            </p>
          </Slide>
          <Slide class="text-center px-4">
            <span class="text-white" style="font-weight:700;font-size:16px">
              Rafael Hernandez Gandia
              <span class="fa fa-star mr-1 ml-2 text-warning"></span>
              <span class="fa fa-star mr-1 text-warning"></span>
              <span class="fa fa-star mr-1 text-warning"></span>
              <span class="fa fa-star mr-1 text-warning"></span>
              <span class="fa fa-star-half text-warning"></span>
            </span>
            <p class="text-white">
              Muy contentos con todo el equipo, te asesoran muy bien en todo, te resuelven dudas y lo más importante, en nuestro caso alquiler, se aseguran a la hora de posibles candidatos,para que den el mejor perfil que andábamos buscando
            </p>
          </Slide>
          <Slide class="text-center px-4">
            <span class="text-white" style="font-weight:700;font-size:16px">
              Cristina Molina García
              <span class="fa fa-star mr-1 ml-2 text-warning"></span>
              <span class="fa fa-star mr-1 text-warning"></span>
              <span class="fa fa-star mr-1 text-warning"></span>
              <span class="fa fa-star mr-1 text-warning"></span>
              <span class="fa fa-star text-warning"></span>
            </span>
            <p class="text-white">
              Recomiendo Avanza, por que no sólo te ofrece un inmueble, sino un acompañamiento en todo el proceso de principio hasta la firma de la compra.  Te ayuda en el filtro de búsqueda, facilita la documentación necesaria y se involucra en lo posible ya que entienden que es una decisión importante. Además si quieres hacer una reforma tienen un equipo adjunto para asesorarte y proponerte posibilidades que ofrece el inmueble.            
            </p>
          </Slide>
          </Carousel>  
        </div>
      </div>
    </div>
  </section>

  <section class="pb70">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col">
          <h3 class="text-center mb-5" style="font-weight:400">
            Trabajamos con más de <span style="background-color:#E45726;color:white;">25 portales...</span>
          </h3>
          <Carousel :perPageCustom="[[0,1],[480, 2], [768, 3], [992,4]]" :autoplay="true" :loop="true">
          <Slide class="text-center px-4">
            <img src="../assets/static/portals/13.png" alt="" height="70px">
          </Slide>
          <Slide class="text-center px-4">
            <img src="../assets/static/portals/21.png" alt="" height="70px">
          </Slide>
          <Slide class="text-center px-4">
            <img src="../assets/static/portals/32.png" alt="" height="70px">
          </Slide>
          <Slide class="text-center px-4">
            <img src="../assets/static/portals/42.png" alt="" height="70px">
          </Slide>
          <Slide class="text-center px-4">
            <img src="../assets/static/portals/Logo_Fotocasa1.png" alt="" height="70px">
          </Slide>
          <Slide class="text-center px-4">
            <img src="../assets/static/portals/logo-yaencontre1.png" alt="" height="70px">
          </Slide>
          <Slide class="text-center px-4">
            <img src="../assets/static/portals/logoindomio-11.png" alt="" height="70px">
          </Slide>
          <Slide class="text-center px-4">
            <img src="../assets/static/portals/pisos1.png" alt="" height="70px">
          </Slide>
          </Carousel>  
        </div>
      </div>
    </div>
  </section>

  <section class="pb70" style="background-color:#F4F4F4">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <h2 class="mb-5">
            <span style="color:#E45726">Vende</span> tu piso en 1 mes,<br> ¡Valoramos tu casa <span style="color:#E45726">gratis!</span>
          </h2>
          <p class="mb-5">Aprovecha la última tecnología para vender tu inmueble,acompañado de profesionales del sector, recibe contactos,y agiliza su venta al mejor precio con nosotros.</p>
          <router-link to="/vender-alquilar" class="btn btn-block orange-button">Quiero vender mi piso en tiempo récord</router-link>
        </div>
        <div class="col-lg-2">
        </div>
        <div class="col-lg-4">
          <img src="../assets/static/house.png" alt="" class="fuid-img">
        </div>
      </div>
    </div>
  </section>  

  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.VueCarousel-dot--active {
  background-color: #E45726 !important;
}
#search,.banner-home{
  background: url(../assets/static/inmuebles-home.png);
  background-repeat: no-repeat;
  background-size: cover;
}
#opiniones{
  background: url(../assets/static/opinions.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.multiselect__option:hover,.multiselect__option--highlight, .multiselect__tags-wrap * {
  background: #3B82F6 !important;
}
.multiselect__option--selected:hover{
  background: #74a9fd !important;
  color: #75889e!important;
}
.search_bar, .search_bar>.multiselect__tags{
  height: 40px !important;
  border:none!important;
}
.separator{
  background-color:rgb(206, 206, 206);
  height: 75px;
  width: 1px;
}
.search-tab-selected{
  background-color:#E45726 !important;
  color: white;
  font-weight: 700;
  border:none !important
}
.orange-button{
  background-color:#E45726 !important;
  color: white;
  font-weight: 700;
  border:none !important;
  border-radius:100px;
}
.search-tab-selected:before {
    content: '';
    width: 15px;
    height: 15px;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform:translate(-50%,0);
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #E45726;
}
.search-tab{
  background-color:white !important;
  color:#E45726 !important;
  font-weight: 700;
  border:none !important
}
.flecha-boton{
  position:absolute;
  height: 15px;
  width: 10px;
  background: url('../assets/static/arrow.png') center center;
  background-repeat: no-repeat;
  background-size: cover;
  right:20px;
  bottom:15px;
}
</style>
<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import Property from '@/components/Property.vue'
import engineFields from '@/engine_fields.json'
import Multiselect from 'vue-multiselect'
import { Carousel, Slide } from 'vue-carousel';
export default {
  name: 'Home',
  components: {
    HelloWorld,
    Property,
    Multiselect,
    Carousel,
    Slide
  },
  data(){
    return {
      inmuebles:[],
      cookies1:null,
      cookies2:null,
      prop_tipos:engineFields.tipos,
      prop_ciudades:engineFields.ciudades,
      operacion:'venta',
      tipos:[],
      ciudad:null,
      precio_desde:null,
      precio_hasta:null,
      novedades:[]
    }
  },
  created(){
    /*
    $cookies.remove("avanza_favorite_properties");
    $cookies.remove("avanza_visited_properties");
    */
    this.getInmueblesDestacados();
    this.getUltimosInmuebles();
    this.cookies1=this.$cookies.get("avanza_favorite_properties");
    this.cookies2=$cookies.get("avanza_visited_properties")
  },
  methods:{
    async getInmueblesDestacados(){
      try {
          const inmueblesDB = await this.axios.get('inmovilla/destacados');
          await inmueblesDB.data.paginacion.shift()
          await inmueblesDB.data.paginacion.forEach(element => {
            this.inmuebles.push(element)
          });
      } catch (error) {
      }
    },
    async getUltimosInmuebles(){
      try {
          const inmueblesDB = await this.axios.get('inmovilla/inmuebles');
          await inmueblesDB.data.paginacion.shift()
          await inmueblesDB.data.paginacion.forEach(element => {
            this.novedades.push(element)
          });
      } catch (error) {
      }
    },
    searchProperties(){
      var query = 'operacion='+this.operacion;
      var cod_tipos = this.tipos.map(x => x.cod_tipo)
      query = (this.tipos.length == 0) ? query : query += ('&tipos='+cod_tipos) 
      query = (this.ciudad == null) ? query : query += ('&ciudad='+this.ciudad.cod_ciu)
      query = (this.precio_desde == null || this.precio_desde == 0) ? query : query += ('&preciodesde='+this.precio_desde)
      query = (this.precio_hasta == null || this.precio_hasta == 0) ? query : query += ('&preciohasta='+this.precio_hasta)
      this.$router.push('listado-inmuebles?'+query);
    }
  }
}
</script>
