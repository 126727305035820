import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/vender-alquilar',
    name: 'Sell',
    component: () => import(/* webpackChunkName: "about" */ '../views/Sell.vue')
  },
  {
    path: '/encontrar-un-hogar',
    name: 'Buy',
    component: () => import(/* webpackChunkName: "about" */ '../views/Buy.vue')
  },
  {
    path: '/listado-inmuebles',
    name: 'Listado',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Listing.vue'),
    props: true 
  },
  {
    path: '/inmueble/:id',
    name: 'Inmueble',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Property.vue'),
    props: true 
  },
  {
    path: '/politica-de-privacidad',
    name: 'Privacidad',
    component: () => import(/* webpackChunkName: "about" */ '../views/Privacidad.vue')
  },
  {
    path: '/politica-de-cookies',
    name: 'Cookies',
    component: () => import(/* webpackChunkName: "about" */ '../views/Cookies.vue')
  },
  {
    path: '/aviso-legal',
    name: 'AvisoLegal',
    component: () => import(/* webpackChunkName: "about" */ '../views/AvisoLegal.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
